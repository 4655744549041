// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-news-templates-event-js": () => import("./../../../src/news-templates/event.js" /* webpackChunkName: "component---src-news-templates-event-js" */),
  "component---src-news-templates-news-event-js": () => import("./../../../src/news-templates/news-event.js" /* webpackChunkName: "component---src-news-templates-news-event-js" */),
  "component---src-news-templates-news-js": () => import("./../../../src/news-templates/news.js" /* webpackChunkName: "component---src-news-templates-news-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-activities-communities-js": () => import("./../../../src/pages/activities-communities.js" /* webpackChunkName: "component---src-pages-activities-communities-js" */),
  "component---src-pages-career-page-js": () => import("./../../../src/pages/career-page.js" /* webpackChunkName: "component---src-pages-career-page-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-page-contentful-newsevents-slug-js": () => import("./../../../src/pages/news-page/{ContentfulNewsevents.slug}.js" /* webpackChunkName: "component---src-pages-news-page-contentful-newsevents-slug-js" */),
  "component---src-pages-products-and-services-index-js": () => import("./../../../src/pages/products-and-services/index.js" /* webpackChunkName: "component---src-pages-products-and-services-index-js" */),
  "component---src-pages-products-and-services-services-cmo-js": () => import("./../../../src/pages/products-and-services/services/cmo.js" /* webpackChunkName: "component---src-pages-products-and-services-services-cmo-js" */),
  "component---src-pages-products-and-services-services-process-analytical-development-js": () => import("./../../../src/pages/products-and-services/services/process-analytical-development.js" /* webpackChunkName: "component---src-pages-products-and-services-services-process-analytical-development-js" */),
  "component---src-pages-products-and-services-services-toll-in-testing-js": () => import("./../../../src/pages/products-and-services/services/toll-in-testing.js" /* webpackChunkName: "component---src-pages-products-and-services-services-toll-in-testing-js" */)
}

